var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{staticClass:"custom__modal",attrs:{"id":_vm.idModal,"hide-footer":"","ok-only":"","ok-title":"Accept","centered":"","no-close-on-backdrop":"","size":"md","title":"Unduh Laporan"}},[_c('b-row',{staticClass:"p-2"},[_c('b-col',{staticClass:"mb-2 custom__form--input",attrs:{"cols":"12"}},[_c('b-form-group',[_c('label',{attrs:{"for":"v-jenis-pajak"}},[_vm._v("Jenis Laporan")]),_c('b-form-select',{staticClass:"text-capitalize",attrs:{"id":"v-jenis-pajak","options":_vm.filteredOptions},model:{value:(_vm.formPayload.report_type),callback:function ($$v) {_vm.$set(_vm.formPayload, "report_type", $$v)},expression:"formPayload.report_type"}})],1)],1),(_vm.formPayload.report_type)?_c('b-col',{staticClass:"mb-2 custom__form--input",attrs:{"cols":"12"}},[(_vm.formPayload.report_type === 'actual_stock')?_c('div',{staticClass:"w-100"},[_c('label',{staticClass:"text-dark size12 fw-bold-600",attrs:{"for":"second-date-datepicker"}},[_vm._v("Tanggal Akhir"),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('b-form-datepicker',{staticClass:"custom__input size12 text-dark fw-bold-600 bg-light bg-line",attrs:{"id":"second-date-datepicker","max":_vm.max,"date-format-options":{
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          },"placeholder":"DD/MM/YYYY","locale":"id"},model:{value:(_vm.formPayload.end_date),callback:function ($$v) {_vm.$set(_vm.formPayload, "end_date", $$v)},expression:"formPayload.end_date"}})],1):_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"w-50 pr-1"},[_c('label',{staticClass:"text-dark size12 fw-bold-600",attrs:{"for":"first-date-datepicker"}},[_vm._v("Tanggal Awal"),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('b-form-datepicker',{staticClass:"custom__input size12 text-dark fw-bold-600 bg-light bg-line",attrs:{"id":"first-date-datepicker","max":_vm.max,"date-format-options":{
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
            },"placeholder":"DD/MM/YYYY","locale":"id"},model:{value:(_vm.formPayload.start_date),callback:function ($$v) {_vm.$set(_vm.formPayload, "start_date", $$v)},expression:"formPayload.start_date"}})],1),_c('div',{staticClass:"w-50"},[_c('label',{staticClass:"text-dark size12 fw-bold-600",attrs:{"for":"second-date-datepicker"}},[_vm._v("Tanggal Akhir"),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('b-form-datepicker',{staticClass:"custom__input size12 text-dark fw-bold-600 bg-light bg-line",attrs:{"id":"second-date-datepicker","max":_vm.max,"date-format-options":{
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
            },"placeholder":"DD/MM/YYYY","locale":"id"},model:{value:(_vm.formPayload.end_date),callback:function ($$v) {_vm.$set(_vm.formPayload, "end_date", $$v)},expression:"formPayload.end_date"}})],1)])]):_vm._e(),(
      _vm.formPayload.report_type &&
      _vm.formPayload.report_type !== 'actual_stock' &&
      _vm.formPayload.report_type !== 'transfer_stock'
    )?_c('b-col',{staticClass:"mb-2 custom__form--input",attrs:{"cols":"12"}},[_c('b-form-group',[_c('label',{attrs:{"for":"v-cabang"}},[_vm._v("Cabang")]),_c('v-select',{staticClass:"custom__input",attrs:{"id":"v-pemasok","options":_vm.optionsCabang,"label":"title","placeholder":"Pilih Cabang"},on:{"search":_vm.onSearchCabang},model:{value:(_vm.formPayload.branch_uuid),callback:function ($$v) {_vm.$set(_vm.formPayload, "branch_uuid", $$v)},expression:"formPayload.branch_uuid"}})],1)],1):_vm._e(),(
      _vm.formPayload.report_type && _vm.formPayload.report_type == 'transfer_stock'
    )?_c('b-col',{staticClass:"mb-2 custom__form--input",attrs:{"cols":"12"}},[_c('b-form-group',[_c('label',{attrs:{"for":"v-cabang"}},[_vm._v("Cabang")]),_c('v-select',{staticClass:"custom__input",attrs:{"id":"v-pemasok","options":_vm.optionsCabang,"label":"title","placeholder":"Pilih Cabang"},on:{"search":_vm.onSearchCabang},model:{value:(_vm.formPayload.branch_origin_uuid),callback:function ($$v) {_vm.$set(_vm.formPayload, "branch_origin_uuid", $$v)},expression:"formPayload.branch_origin_uuid"}})],1)],1):_vm._e(),(
      _vm.formPayload.report_type &&
      _vm.formPayload.report_type !== 'utang' &&
      _vm.formPayload.report_type !== 'supplier_payment' &&
      _vm.formPayload.report_type !== 'other_payment' &&
      _vm.formPayload.report_type !== 'actual_stock' &&
      _vm.formPayload.report_type !== 'piutang' &&
      _vm.formPayload.report_type !== 'piutang_payment' &&
      _vm.formPayload.report_type !== 'product_stock' &&
      _vm.formPayload.report_type !== 'transfer_stock' &&
      _vm.formPayload.report_type !== 'gross_profit' &&
      _vm.formPayload.report_type !== 'stock_opname'
    )?_c('b-col',{staticClass:"mb-2 custom__form--input",attrs:{"cols":"12"}},[_c('b-form-group',[_c('label',{attrs:{"for":"v-jenis-pajak"}},[_vm._v("Jenis Pajak")]),_c('b-form-select',{attrs:{"id":"v-jenis-pajak","options":[
          { value: '', text: 'Pilih Jenis Pajak' },
          { value: '0', text: 'Pajak' },
          { value: '1', text: 'Non-Pajak' },
          { value: 'semua', text: 'Semua' } ]},model:{value:(_vm.formPayload.non_tax),callback:function ($$v) {_vm.$set(_vm.formPayload, "non_tax", $$v)},expression:"formPayload.non_tax"}})],1)],1):_vm._e(),(
      (_vm.formPayload.report_type && _vm.formPayload.report_type == 'order') ||
      _vm.formPayload.report_type == 'piutang' ||
      _vm.formPayload.report_type == 'piutang_payment'
    )?_c('b-col',{staticClass:"mb-2 custom__form--input",attrs:{"cols":"12"}},[_c('b-form-group',[_c('label',{attrs:{"for":"v-konsumen"}},[_vm._v("Konsumen")]),_c('v-select',{staticClass:"custom__input",attrs:{"id":"v-konsumen","placeholder":"Pilih Konsumen","options":_vm.optionsKonsumen,"label":"title"},on:{"search":_vm.onSearchKonsumen},model:{value:(_vm.formPayload.customer_uuid),callback:function ($$v) {_vm.$set(_vm.formPayload, "customer_uuid", $$v)},expression:"formPayload.customer_uuid"}})],1)],1):_vm._e(),(
      (_vm.formPayload.report_type &&
        _vm.formPayload.report_type == 'supplier_payment') ||
      _vm.formPayload.report_type == 'utang' ||
      _vm.formPayload.report_type == 'incoming_stock_supplier' ||
      _vm.formPayload.report_type == 'incoming_stock_warehouse'
    )?_c('b-col',{staticClass:"mb-2 custom__form--input",attrs:{"cols":"12"}},[_c('b-form-group',[_c('label',{attrs:{"for":"v-pemasok"}},[_vm._v("Pemasok")]),_c('v-select',{staticClass:"custom__input",attrs:{"id":"v-pemasok","placeholder":"Pilih Pemasok","options":_vm.optionsPemasok,"label":"title"},on:{"search":_vm.onSearchPemasok},model:{value:(_vm.formPayload.supplier_uuid),callback:function ($$v) {_vm.$set(_vm.formPayload, "supplier_uuid", $$v)},expression:"formPayload.supplier_uuid"}})],1)],1):_vm._e(),(
      (_vm.formPayload.report_type && _vm.formPayload.report_type == 'utang') ||
      _vm.formPayload.report_type == 'piutang' ||
      _vm.formPayload.report_type == 'piutang_payment'
    )?_c('b-col',{staticClass:"mb-2 custom__form--input",attrs:{"cols":"12"}},[_c('b-form-group',[_c('label',{staticStyle:{"text-transform":"capitalize"},attrs:{"for":"due-date-datepicker"}},[_vm._v(" "+_vm._s(_vm.formPayload.report_type === "piutang_payment" ? "Pembayaran Piutang" : _vm.formPayload.report_type)+" Jatuh Tempo ")]),_c('b-form-datepicker',{staticClass:"custom__input size12 text-dark fw-bold-600 bg-light bg-line",attrs:{"id":"due-date-datepicker","date-format-options":{
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          },"placeholder":"DD/MM/YYYY","locale":"id"},model:{value:(_vm.formPayload.due_date),callback:function ($$v) {_vm.$set(_vm.formPayload, "due_date", $$v)},expression:"formPayload.due_date"}})],1)],1):_vm._e(),(
      (_vm.formPayload.report_type &&
        _vm.formPayload.report_type === 'actual_stock') ||
      _vm.formPayload.report_type === 'stock_opname'
    )?_c('b-col',{staticClass:"mb-2 custom__form--input",attrs:{"cols":"12"}},[_c('b-form-group',[_c('label',{attrs:{"for":"v-lokasi-sku"}},[_vm._v("Lokasi SKU")]),_c('v-select',{staticClass:"custom__input",attrs:{"id":"v-lokasi-sku","placeholder":"Pilih Lokasi SKU","options":_vm.optionsLokasi,"label":"title"},on:{"search":_vm.onSearchLokasi},model:{value:(_vm.formPayload.warehouse_uuid),callback:function ($$v) {_vm.$set(_vm.formPayload, "warehouse_uuid", $$v)},expression:"formPayload.warehouse_uuid"}})],1)],1):_vm._e(),(_vm.formPayload.report_type && _vm.formPayload.report_type == 'cashier')?_c('b-col',{staticClass:"mb-2 custom__form--input",attrs:{"cols":"12"}},[_c('b-form-group',[_c('label',{attrs:{"for":"v-kasir"}},[_vm._v("Kasir")]),_c('v-select',{staticClass:"custom__input",attrs:{"id":"v-kasir","placeholder":"Pilih Kasir","options":_vm.optionsKasir,"label":"title"},on:{"search":_vm.onSearchKasir},model:{value:(_vm.formPayload.operator_uuid),callback:function ($$v) {_vm.$set(_vm.formPayload, "operator_uuid", $$v)},expression:"formPayload.operator_uuid"}})],1)],1):_vm._e(),(
      _vm.formPayload.report_type && _vm.formPayload.report_type == 'product_stock'
    )?_c('b-col',{staticClass:"mb-2 custom__form--input",attrs:{"cols":"12"}},[_c('b-form-group',[_c('label',{attrs:{"for":"v-produk"}},[_vm._v("Nama SKU"),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('v-select',{staticClass:"custom__input",attrs:{"id":"v-produk","placeholder":"Pilih Nama SKU","options":_vm.optionsProduk,"label":"title"},on:{"search":_vm.onSearchProduk},model:{value:(_vm.formPayload.product_uuid),callback:function ($$v) {_vm.$set(_vm.formPayload, "product_uuid", $$v)},expression:"formPayload.product_uuid"}})],1)],1):_vm._e()],1),_c('div',{staticClass:"button__group"},[_c('b-button',{staticClass:"button__cancel",attrs:{"id":"button--cancel__transaction"},on:{"click":function($event){return _vm.$bvModal.hide(_vm.idModal)}}},[_vm._v(" Batal ")]),_c('b-button',{staticClass:"button__save",attrs:{"id":"button--save__transaction","disabled":(_vm.formPayload.report_type === 'product_stock' &&
      !_vm.formPayload.product_uuid) ||
      !_vm.formPayload.end_date},on:{"click":function($event){return _vm.exportReport(_vm.type)}}},[(_vm.isLoadingExport)?_c('b-spinner',{attrs:{"variant":"primary","label":"Loading..."}}):_vm._e(),_vm._v(" Simpan ")],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }